<template>
  <div
    class="grid h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8"
  >
    <div class="text-center">
      <h1
        class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl"
      >
        {{ title }}
      </h1>
      <p class="mt-6 text-base leading-7 text-gray-600">
        {{ text }}
      </p>
      <div class="mt-10 flex items-center justify-center gap-x-4">
        <img class="h-8 w-8" src="/icon.svg" alt="Healistic" />
        <p class="text-xl font-semibold text-viola-400">Healistic</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { error } = defineProps({
  error: {
    type: Object,
    required: true,
  },
});

const title =
  error.statusCode === 404
    ? "404 - Page not found"
    : `${error.statusCode} - Unexpected error`;
const text =
  error.statusCode === 404
    ? "The page you are looking for does not exist. Please check the entered URL and try again."
    : "An unexpected error occurred. Please try again later.";
</script>
